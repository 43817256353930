import React from 'react'; 
import Header from '../Header';
import Footer from '../Footer';
import Topslider from '../Topslider';
import Serviceboxhome from '../Serviceboxhome';
import Aboutwhychooseusbox from '../Aboutwhychooseusbox';
import Counterhome from '../Counterhome';
import Testimonialhome from '../Testimonialhome';

class Services extends React.Component {
    render() {
      return (
            <>
                <Header />
                    <main class="newsko-distribution scrolled-offset"  id="main">
  <section class="portfolio_banner newsko-distribution">
    <div class="container">
      <div class="section-title">
        <p class="web_indicator mb-2">Portfolio / Web</p>
        <h2 class="welcome_title">Welcome to Newsko Distribution </h2>
        <p>Newsko Distribution  is a distributor focused on creating value through competence, quality, and innovation. They offer a wide range of Information &amp; Communication Technology (ICT) products and have a presence in multiple sales offices across the national territory. </p>
      </div>
      <div class="project_banner"><img src={`${process.env.PUBLIC_URL}/assets/img/portfolio/newsko_1.png`} alt="Newsko Distribution" /></div>
    </div>
  </section>
  <section class="about_project">
    <div class="container">
      <div class="section-title">
        <h2 class="welcome_title">Newsko Distribution -  Empowering Businesses with Innovative ICT Solutions </h2>
        <p> Newsko Distribution  is a dynamic value distributor that prioritizes competence, quality, and innovation in its mission. With a diverse portfolio of Information &amp; Communication Technology products, an extensive national presence, efficient logistics, and an advanced e-commerce platform, Newsko Distribution  stands out as a trusted partner for Computer Shops, VARs, System Integrators, Chains, E-tailers, and GDs. </p>
         <p>  At Newsko Distribution , we understand the importance of providing technical-commercial guidance and equipping our partners with the necessary tools to thrive in an ever-evolving market. </p>
         <p>  Whether you are seeking cutting-edge technologies, reliable products, or expert advice, Newsko Distribution  is here to support you in your business journey. Join us as we navigate the future together, making informed decisions and embracing opportunities for growth. </p>
        <div class="visit_web headerContactCTA"> <a href="https://www.newsko-distribution.com/" target="_blank" class="">Visit website</a> </div>
      </div>
    </div>
  </section>
  <section class="project_details">
    <div class="container">
      <div class="row">
        <div class="col-md-3">
          <div class="info_card">
            <div class="card_icon name"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/project_icon1.png`} alt="Newsko Distribution" /></div>
            <div class="card_info">
              <p class="label">Project Name</p>
              <h3>Newsko Distribution </h3>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="info_card">
            <div class="card_icon ctg"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/project_icon2.png`} alt="Newsko Distribution" /></div>
            <div class="card_info">
              <p class="label">Category</p>
              <h3>e-commerce</h3>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="info_card">
            <div class="card_icon location"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/project_icon3.png`} alt="Newsko Distribution" /></div>
            <div class="card_info">
              <p class="label">Client Location</p>
              <h3>Europe</h3>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="info_card">
            <div class="card_icon service"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/project_icon4.png`} alt="Newsko Distribution" /></div>
            <div class="card_info">
              <p class="label">Services Provided</p>
              <h3>Design, Development, QA, Deployment</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="project_features">
    <div class="container">
      <div class="section-title">
        <h2 class="welcome_title">Features</h2>
      </div>
      <div class="row align-items-center">
        <div class="col-md-4">
          <div class="feature_card">
            <ul>
              <li><strong>Extensive Product Portfolio: </strong>Newsko Distribution offers a broad range of Information &amp; Communication Technology products, ensuring that their partners have access to a diverse selection of innovative and reliable solutions.</li>
              <li><strong>Nationwide Presence:</strong> With multiple sales offices across the country, Newsko Distribution has established a strong presence throughout the national territory, making it convenient for partners to engage and collaborate with them.</li>
              <li><strong>Efficient Logistics:</strong> Newsko Distribution emphasizes efficient logistics, ensuring prompt delivery and reliable supply chain management. This enables partners to meet customer demands efficiently and effectively.</li>
              <li><strong>Advanced E-commerce Platform:</strong> Newsko Distribution's advanced e-commerce platform provides partners with a user-friendly interface to browse, order, and manage their product inventory online. This streamlined process saves time and simplifies business operations. </li>
            </ul>
          </div>
        </div>
        <div class="col-md-4 feature_project_img"> <img src={`${process.env.PUBLIC_URL}/assets/img/portfolio/newskodistribution.png`} alt="Newsko Distribution" /></div>
        <div class="col-md-4">
          <div class="feature_card">
            <ul>
              <li><strong>Technical-Commercial Advice</strong> Newsko Distribution goes beyond being just a distributor; they offer expert technical-commercial advice to their partners. This guidance helps businesses make informed decisions, stay updated with market trends, and identify growth opportunities.</li>
              <li><strong>Trusted Relationships: </strong>Newsko Distribution has built a reputation for establishing strong and trusted relationships with their partners. They prioritize long-term collaboration, providing ongoing support and assistance to ensure mutual success.</li>
              <li><strong>Passion for Innovation: </strong>Newsko Distribution's commitment to innovation drives their approach to the market. They continuously explore new technologies and trends, enabling partners to stay competitive and offer cutting-edge solutions to their customers.</li>
              <li><strong>Business Guidance: </strong>Newsko Distribution acts as a reliable guide for their partners, assisting them in making informed business choices. They leverage their industry expertise and experience to help navigate challenges and capitalize on opportunities for growth.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
    <section class="technologies_stack">
    <div class="container">
      <div class="section-title">
        <h2 class="welcome_title">Technology Stack</h2>
      </div>
      <div class="row">
        <div class="col-md-2 mrgn_top">
          <div class="info_card">
            <div class="card_icon clr1"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/ui-ux_icon-2.png`} alt="Newsko Distribution" /></div>
            <div class="card_info">
              <h3>UI/UX </h3>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="info_card">
            <div class="card_icon clr2"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/html-icon.png`} alt="Newsko Distribution" /></div>
            <div class="card_info">
              <h3>HTML 5</h3>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="info_card">
            <div class="card_icon clr6"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/wordpress-icon.png`} alt="Newsko Distribution" /></div>
            <div class="card_info">
              <h3>WordPress</h3>
            </div>
          </div>
        </div>
        <div class="col-md-2 mrgn_top">
          <div class="info_card">
            <div class="card_icon clr3"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/PHP_icon.png`} alt="Newsko Distribution" /></div>
            <div class="card_info">
              <h3>PHP</h3>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="info_card">
            <div class="card_icon clr4"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/mysql.png`} alt="Newsko Distribution" /></div>
            <div class="card_info">
              <h3>MySQL</h3>
            </div>
          </div>
        </div>
        <div class="col-md-2 mrgn_top">
          <div class="info_card">
            <div class="card_icon clr5"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/mannual_software_test.png`} alt="Newsko Distribution" /></div>
            <div class="card_info">
              <h3>Mannual Testing</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="features_img_block">
    <div class="container">
      <div class="row">
        <div class="col-md-6"> <img class="w-100" src={`${process.env.PUBLIC_URL}/assets/img/portfolio/newsko_4.jpg`} alt="Newsko Distribution" /></div>
        <div class="col-md-6"> <img class="w-100" src={`${process.env.PUBLIC_URL}/assets/img/portfolio/newsko_5.jpg`} alt="Newsko Distribution" /></div>
      </div>
    </div>
  </section>
</main>

                <Footer />
            </>
            );
        }
    }
    
export default Services;