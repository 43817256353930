import React from 'react'; 
import Header from '../Header';
import Footer from '../Footer';
import Topslider from '../Topslider';
import Serviceboxhome from '../Serviceboxhome';
import Aboutwhychooseusbox from '../Aboutwhychooseusbox';
import Counterhome from '../Counterhome';
import Testimonialhome from '../Testimonialhome';

class Services extends React.Component {
    render() {
      return (
            <>
                <Header />
                  
                       <main class="repricer scrolled-offset" id="main">
  <section class="portfolio_banner repricer">
    <div class="container">
      <div class="section-title">
        <p class="web_indicator mb-2">Portfolio / Web</p>
        <h2 class="welcome_title">Welcome To 
          Arsgate Repricer </h2>
        <p>Supercharge your Amazon sales and maximize profits with the #1 Repricer. Win the Buy Box more often, beat competitors with lightning-fast repricing, and automate your pricing strategy. Stay ahead of the game with real-time competitor tracking and optimize your product prices for increased sales and profitability.</p>
      </div>
      <div class="project_banner"><img src={`${process.env.PUBLIC_URL}/assets/img/portfolio/repricer_1.png`} alt="arsgate repricer" /></div>
    </div>
  </section>
  <section class="about_project">
    <div class="container">
      <div class="section-title">
        <h2 class="welcome_title">Gain the Competitive Edge with Our Powerful Amazon Repricer </h2>
        <p>Boost sales, maximize profits, and win the Amazon Buy Box with our powerful repricer. Stay ahead of competitors with real-time price adjustments and automated repricing strategies.</p>
         <p> Maximize Amazon sales and profits with our powerful repricer. Win the Buy Box, beat competitors, and automate your pricing strategy for optimal success. Increase sales with precision repricing. </p><p>
          Boost Amazon sales and maximize profits with our powerful repricer. Win the Buy Box, outpace competitors with lightning-fast repricing, and automate pricing strategies. Stay ahead of the game and optimize your listings for success on Amazon. </p>
        <div class="visit_web headerContactCTA"> <a href="https://repricer.arsgate.com/admin/users/users/login" target="_blank" class="">Visit website</a> </div>
      </div>
    </div>
  </section>
  <section class="project_details">
    <div class="container">
      <div class="row">
        <div class="col-md-3">
          <div class="info_card">
            <div class="card_icon name"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/project_icon1.png`} alt="arsgate repricer" /></div>
            <div class="card_info">
              <p class="label">Project Name</p>
              <h3> Arsgate Repricer </h3>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="info_card">
            <div class="card_icon ctg"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/project_icon2.png`} alt="arsgate repricer" /></div>
            <div class="card_info">
              <p class="label">Category</p>
              <h3>e-commerce</h3>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="info_card">
            <div class="card_icon location"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/project_icon3.png`} alt="arsgate repricer" /></div>
            <div class="card_info">
              <p class="label">Client Location</p>
              <h3>Europe</h3>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="info_card">
            <div class="card_icon service"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/project_icon4.png`} alt="arsgate repricer" /></div>
            <div class="card_info">
              <p class="label">Services Provided</p>
              <h3>Design, Development, QA, Deployment</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="project_features">
    <div class="container">
      <div class="section-title">
        <h2 class="welcome_title">Features</h2>
      </div>
      <div class="row align-items-center">
        <div class="col-md-4">
          <div class="feature_card">
            <ul>
              <li><strong>Buy Box Optimization: </strong>Increase your chances of winning the Buy Box, as over 83% of Amazon sales go to sellers who are in the Buy Box. Our repricer adjusts prices based on multiple factors, including price, fulfillment, feedback, and inventory depth.</li>
              <li><strong>Competitor Tracking:</strong> Stay ahead of your competitors by tracking their prices. Our repricer keeps you informed about competitor pricing movements, allowing you to adjust your prices proactively and strategically.</li>
              <li><strong>Seller Evaluation:</strong> Evaluate different attributes of your competitors, such as seller rating, pricing, and fulfillment, to identify the sellers you need to beat. This helps you create a winning pricing strategy and gain a competitive edge.</li>
              <li><strong>Proactive Repricing: </strong> Use our Kick-Start feature to adjust your prices and make competitors react. Combined with our superior repricing speed, you can quickly secure the Buy Box and outperform your rivals.</li>
            </ul>
          </div>
        </div>
        <div class="col-md-4 feature_project_img"><img src={`${process.env.PUBLIC_URL}/assets/img/portfolio/repricer-5.png`} alt="arsgate repricer" /></div>
        <div class="col-md-4">
          <div class="feature_card">
            <ul>
              <li><strong>Buy Box Chaser:</strong> Aggressively price your products when you're not in the Buy Box to increase your chances of winning it back. Our repricer helps you target the Buy Box and maximize your sales potential.</li>
              <li><strong>Buy Box Optimizer: </strong>Maximize your profits and retain the Buy Box for longer. Our repricer nudges your price up when you're already in the Buy Box, ensuring that you make the most of your sales while staying competitive.</li>
              <li><strong>Sales-Based Repricing: </strong> Automatically adjust your product prices when there is no competition on a product listing. This helps ensure that your inventory keeps moving, even when there is limited competition.</li>
              <li><strong>Suppressed Buy Box Strategy:</strong> When the Buy Box is suppressed or removed from a listing, our repricer allows you to create a winning strategy on how to compete effectively. Maintain a competitive edge and continue generating sales even in challenging circumstances.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="technologies_stack">
    <div class="container">
      <div class="section-title">
        <h2 class="welcome_title">Technology Stack</h2>
      </div>
      <div class="row">
        <div class="col-md-2 mrgn_top">
          <div class="info_card">
            <div class="card_icon clr1"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/ui-ux_icon-2.png`} alt="arsgate repricer" /></div>
            <div class="card_info">
              <h3>UI/UX </h3>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="info_card">
            <div class="card_icon clr2"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/cake-icon.png`} alt="arsgate repricer" /></div>
            <div class="card_info">
              <h3>CAKE</h3>
            </div>
          </div>
        </div>
        <div class="col-md-2 mrgn_top">
          <div class="info_card">
            <div class="card_icon clr3"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/PHP_icon.png`} alt="arsgate repricer" /></div>
            <div class="card_info">
              <h3>PHP</h3>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="info_card">
            <div class="card_icon clr4"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/mysql.png`} alt="arsgate repricer" /></div>
            <div class="card_info">
              <h3>MySQL</h3>
            </div>
          </div>
        </div>
        <div class="col-md-2 mrgn_top">
          <div class="info_card">
            <div class="card_icon clr6"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/api-icon.png`} alt="arsgate repricer" /></div>

            <div class="card_info">
              <h3>MWS, AWS </h3>
            </div>
          </div>
        </div>
        <div class="col-md-2 ">
          <div class="info_card">
            <div class="card_icon clr5"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/mannual_software_test.png`} alt="arsgate repricer" /></div>
            <div class="card_info">
              <h3>Mannual Testing</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="features_img_block">
    <div class="container">
      <div class="row">
        <div class="col-md-6"><img class="w-100" src={`${process.env.PUBLIC_URL}/assets/img/portfolio/repricer_ss1-600x300.png`} alt="arsgate repricer" /> </div>
        <div class="col-md-6"> <img class="w-100" src={`${process.env.PUBLIC_URL}/assets/img/portfolio/repricer_ss2-768x384.png`} alt="arsgate repricer" /> </div>
      </div>
    </div>
  </section>
</main>
                <Footer />
            </>
            );
        }
    }
    
export default Services;