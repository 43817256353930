import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import Portfoliosectionbox from "../Portfoliosectionbox";
import { Link } from "react-router-dom";

class Portfolio extends React.Component {
  render() {
    return (
      <>
        <Header />
        <main id="main">
          <section class="partner-block scrolled-offset">
            <div class="container">
              <img
                src={`${process.env.PUBLIC_URL}/assets/img/partners_img.png`}
                class="img-fluid"
                alt="our partner"
              />
              <h2 class="mb-3">
                renton technology SRL Collaboration With Rackron Technologies
                PVT. LTD.
              </h2>
              <p>
                We are pleased to announce a strategic partnership between
                Renton Technology SRL (Europe) and Rackron Technologies PVT LTD
                (India) , aimed at harnessing our respective strengths and
                expertise to create new opportunities and drive mutual growth.
                This partnership will leverage the complementary nature of our
                businesses to deliver enhanced value to our customers and
                stakeholders.
              </p>
              <p>
                Through this collaboration, Renton Technology SRL (Europe) and
                Rackron Technologies PVT LTD (India) will combine their
                technological capabilities, market insights, and resources to
                develop innovative solutions that address the evolving needs of
                the market. By pooling our expertise, we aim to accelerate the
                pace of innovation and create cutting-edge products and services
                that will revolutionize the industry.
              </p>
              <div class="view-projects">
                <h3>
                  Discover Our Inspiring Projects! Take a Closer Look Today
                </h3>
                <p>
                  Are you ready to be inspired? Explore our diverse range of
                  projects that showcase creativity, innovation, and passion.
                  From cutting-edge technologies to artistic masterpieces, our
                  portfolio is a testament to our commitment to excellence.
                </p>
                <Link to="/allsites" className="btn btn-primary">
                  View All Projects
                </Link>
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </>
    );
  }
}

export default Portfolio;
