import React from 'react'; 
import Header from '../Header';
import Footer from '../Footer';
import Topslider from '../Topslider';
import Serviceboxhome from '../Serviceboxhome';
import Aboutwhychooseusbox from '../Aboutwhychooseusbox';
import Counterhome from '../Counterhome';
import Testimonialhome from '../Testimonialhome';

class Services extends React.Component {
    render() {
      return (
            <>
                <Header />
                    <main class="orange_eu scrolled-offset" id="main">
  <section class="portfolio_banner orange">
    <div class="container">
      <div class="section-title">
        <p class="web_indicator mb-2">Portfolio / Web</p>
        <h2 class="welcome_title">Welcome to Orange World Wide IT Distributer</h2>
        <p>Orange is a leading IT distributor, connecting manufacturers, vendors, and customers. With a vast product selection and a global network of partners, Orange delivers high-quality solutions. Their 24/7 professional support ensures customer satisfaction, while their commitment to growth and innovation drives success in the ever-changing IT market.</p>
      </div>
      <div class="project_banner"><img src={`${process.env.PUBLIC_URL}/assets/img/portfolio/orange_1.png`} alt="Orange" /></div>
    </div>
  </section>
  <section class="about_project">
    <div class="container">
      <div class="section-title">
        <h2 class="welcome_title">Orange - Your Premier IT Distribution Partner </h2>
        <p>Orange Europe is the premier IT distribution partner that businesses across Europe rely on for their technology needs. With an established reputation as a leader in the industry, Orange Europe offers a comprehensive range of branded products, computer components, storage solutions, and software. </p>
         <p>
          Our commitment to providing exceptional customer service sets us apart, with 24/7 professional support and a dedication to helping our customers succeed. Whether you are a small business or a large enterprise, Orange Europe is here to be your trusted partner, ensuring security and compliance, and supporting your growth in the ever-evolving IT landscape. </p>
         <p>
          Orange Europe is dedicated to providing 100% customizable solutions, ensuring security and compliance, fostering growth opportunities, and offering 24/7 professional support. 
          With a commitment to excellence and a wide range of offerings, Orange Europe is the trusted partner for IT solutions across Europe. </p>
        <div class="visit_web headerContactCTA"> <a href="https://orange-eu.com/" target="_blank" class="">Visit website</a> </div>
      </div>
    </div>
  </section>
  <section class="project_details">
    <div class="container">
      <div class="row">
        <div class="col-md-3">
          <div class="info_card">
            <div class="card_icon name"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/project_icon1.png`} alt="Orange" /></div>
            <div class="card_info">
              <p class="label">Project Name</p>
              <h3>Orange World Wide IT Distributer</h3>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="info_card">
            <div class="card_icon ctg"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/project_icon2.png`} alt="Orange" /></div>
            <div class="card_info">
              <p class="label">Category</p>
              <h3>e-commerce</h3>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="info_card">
            <div class="card_icon location"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/project_icon3.png`} alt="Orange" /></div>
            <div class="card_info">
              <p class="label">Client Location</p>
              <h3>Europe</h3>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="info_card">
            <div class="card_icon service"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/project_icon4.png`} alt="Orange" /></div>
            <div class="card_info">
              <p class="label">Services Provided</p>
              <h3>Design, Development, QA, Deployment</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="project_features">
    <div class="container">
      <div class="section-title">
        <h2 class="welcome_title">Features</h2>
      </div>
      <div class="row align-items-center">
        <div class="col-md-4">
          <div class="feature_card">
            <ul>
              <li><strong>Extensive Product Range: </strong>Orange offers an extensive range of products, including branded devices, computer components, storage solutions, and software. Their diverse portfolio caters to a wide range of customer needs and preferences.</li>
              <li><strong>Customizability:</strong> Orange provides 100% customizable solutions, allowing customers to tailor their IT infrastructure and products to meet their specific requirements. This flexibility ensures optimal performance and efficiency.</li>
              <li><strong>Security and Compliance:</strong> Orange prioritizes the security and compliance of its products and services. They implement robust security measures and adhere to industry standards, ensuring data protection and privacy for their customers.</li>
              <li><strong>Growth Opportunities: </strong> Orange is committed to fostering growth opportunities for businesses. They stay ahead of market trends, anticipating new opportunities and connecting their partners with emerging technologies and markets. </li>
            </ul>
          </div>
        </div>
        <div class="col-md-4 feature_project_img"><img src={`${process.env.PUBLIC_URL}/assets/img/portfolio/Orange-web.png`} alt="Orange" /> </div>
        <div class="col-md-4">
          <div class="feature_card">
            <ul>
              <li><strong>24/7 Professional Support:</strong> Orange offers round-the-clock professional support to ensure that customers receive timely assistance and expert guidance. Their dedicated support team is available to address any queries or issues that may arise.</li>
              <li><strong>Worldwide Reputation: </strong> Orange has established a strong worldwide reputation as a trusted IT distributor. Their track record of delivering quality products and services has earned the trust of customers and partners alike.</li>
              <li><strong>Market Knowledge: </strong> Orange possesses in-depth knowledge of the IT market. They stay informed about industry trends, enabling them to provide valuable insights and recommendations to their customers.</li>
              <li><strong>Reliability:</strong> Orange consistently delivers on its promises. They collaborate with reputable manufacturers and vendors, ensuring that customers receive high-quality products and solutions. </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="technologies_stack">
    <div class="container">
      <div class="section-title">
        <h2 class="welcome_title">Technology Stack</h2>
      </div>
      <div class="row">
        <div class="col-md-2 mrgn_top">
          <div class="info_card">
            <div class="card_icon clr1"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/ui-ux_icon-2.png`} alt="Orange" /></div>
            <div class="card_info">
              <h3>UI/UX </h3>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="info_card">
            <div class="card_icon clr2"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/html-icon.png`} alt="Orange" /></div>
            <div class="card_info">
              <h3>HTML 5</h3>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="info_card">
            <div class="card_icon clr6"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/wordpress-icon.png`} alt="Orange" /></div>
            <div class="card_info">
              <h3>WordPress</h3>
            </div>
          </div>
        </div>
        <div class="col-md-2 mrgn_top">
          <div class="info_card">
            <div class="card_icon clr3"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/PHP_icon.png`} alt="Orange" /></div>
            <div class="card_info">
              <h3>PHP</h3>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="info_card">
            <div class="card_icon clr4"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/mysql.png`} alt="Orange" /></div>
            <div class="card_info">
              <h3>MySQL</h3>
            </div>
          </div>
        </div>
        <div class="col-md-2 mrgn_top">
          <div class="info_card">
            <div class="card_icon clr5"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/mannual_software_test.png`} alt="Orange" /></div>
            <div class="card_info">
              <h3>Mannual Testing</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="features_img_block">
    <div class="container">
      <div class="row">
        <div class="col-md-6"> <img class="w-100" src={`${process.env.PUBLIC_URL}/assets/img/portfolio/orange_img3-768x768.png`} alt="Orange" /></div>
        <div class="col-md-6"><img class="w-100" src={`${process.env.PUBLIC_URL}/assets/img/portfolio/orange_img4-768x768.png`} alt="Orange" /></div>
      </div>
    </div>
  </section>
</main>
                <Footer />
            </>
            );
        }
    }
    
export default Services;