import React from "react";
import { Link, NavLink } from "react-router-dom";

class Navbar extends React.Component {
  render() {
    return (
      <>
        <ul className="navbar-nav ml-audio">
          <li className="nav-item">
            <NavLink className="nav-link" exact to="/">
              Home
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" exact to="/aboutus">
              About Us
            </NavLink>
          </li>
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Services
            </a>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
              <li className="nav-item">
                <NavLink className="nav-link" exact to="/webdevelopment">
                  Web Development
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" exact to="/creativedesign">
                  Creative Designs
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" exact to="/mobiledevelopment">
                  Mobile Development
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" exact to="/digitalmarketing">
                  Digital Marketing
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" exact to="/qualitytesting">
                  Quality Testing
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" exact to="/ecommercesolution">
                  E-commerce Solution
                </NavLink>
              </li>
            </ul>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" exact to="/portfolio">
              Portfolio
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" exact to="/ourproducts">
              Our Product
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" exact to="/ourpartners">
              Our Partners
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" exact to="/career">
              Careers
            </NavLink>
          </li>
          <li className="nav-item headerContactCTA">
            <NavLink className="nav-link" exact to="/contactus">
              Contact
            </NavLink>
          </li>
        </ul>
      </>
    );
  }
}

export default Navbar;
