import React from 'react'; 
import Header from '../Header';
import Footer from '../Footer';
import Topslider from '../Topslider';
import Serviceboxhome from '../Serviceboxhome';
import Aboutwhychooseusbox from '../Aboutwhychooseusbox';
import Counterhome from '../Counterhome';
import Testimonialhome from '../Testimonialhome';

class Services extends React.Component {
    render() {
      return (
            <>
                <Header />
                    <main class="freepack_srl scrolled-offset" id="main">
  <section class="portfolio_banner freepack_srl">
    <div class="container">
      <div class="section-title">
        <p class="web_indicator mb-2">Portfolio / Web</p>
        <h2 class="welcome_title">Welcome to PRO Invoice System</h2>
        <p>With Pro Invoice System, you can say goodbye to the hassle of manually requesting and downloading Amazon invoices and receipts. Our powerful software automates these processes for you, ensuring that you have access to the latest financial information at all times. </p>
      </div>
      <div class="project_banner"><img src={`${process.env.PUBLIC_URL}/assets/img/portfolio/proinvoice_1.png`} alt="Pro Invoice System" /> </div>
    </div>
  </section>
  <section class="about_project">
    <div class="container">
      <div class="section-title">
        <h2 class="welcome_title">Streamline Your Amazon Invoices and Receipts with Pro Invoice System </h2>
        <p> Pro Invoice System is a comprehensive software solution designed to streamline and automate the management of your Amazon invoices and receipts. Say goodbye to manual processes and save valuable time and resources by connecting your Amazon account with Pro Invoice System. </p>
        <p> With powerful features such as automated requests and downloads, real-time financial information display, integration with accounting platforms, and a user-friendly dashboard, Pro Invoice System empowers you to take control of your financial data and make informed business decisions.</p>
        <p> Pro Invoice System is a powerful software solution designed to automate and streamline your Amazon invoice and receipt management processes. Say goodbye to manual data entry and tedious paperwork, and say hello to efficiency and accuracy. </p>
      </div>
    </div>
  </section>
  <section class="project_details">
    <div class="container">
      <div class="row">
        <div class="col-md-3">
          <div class="info_card">
            <div class="card_icon name"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/project_icon1.png`} alt="Pro Invoice System" /></div>
            <div class="card_info">
              <p class="label">Project Name</p>
              <h3>Pro Invoice System</h3>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="info_card">
            <div class="card_icon ctg"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/project_icon2.png`} alt="Pro Invoice System" /></div>
            <div class="card_info">
              <p class="label">Category</p>
              <h3>e-commerce</h3>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="info_card">
            <div class="card_icon location"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/project_icon3.png`} alt="Pro Invoice System" /></div>
            <div class="card_info">
              <p class="label">Client Location</p>
              <h3>Europe</h3>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="info_card">
            <div class="card_icon service"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/project_icon4.png`} alt="Pro Invoice System" /></div>
            <div class="card_info">
              <p class="label">Services Provided</p>
              <h3>Design, Development, QA, Deployment</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="project_features">
    <div class="container">
      <div class="section-title">
        <h2 class="welcome_title">Features</h2>
      </div>
      <div class="row align-items-center">
        <div class="col-md-4">
          <div class="feature_card">
            <ul>
              <li><strong>Automated Invoice and Receipt Requests: </strong> Pro Invoice System allows you to set up automated requests and downloads of Amazon invoices and receipts at regular intervals. This eliminates the need for manual retrieval and ensures that you always have the most up-to-date financial information.</li>
              <li><strong>Centralized Dashboard:</strong> All your invoice and receipt data is captured and displayed in a centralized dashboard. This gives you a real-time overview of your financial information, making it easy to track, analyze, and manage your Amazon accounts.</li>
              <li><strong>Integration with Accounting Platforms:</strong> Seamlessly connect Pro Invoice System with your preferred accounting platform. The system enables you to effortlessly send financial information to your accounting software, ensuring smooth synchronization and reducing the need for manual data entry.</li>
              <li><strong>Supplier Management:</strong> Easily manage your suppliers within Pro Invoice System. Keep track of supplier information, track orders, and stay organized when it comes to managing your business relationships.</li>
            </ul>
          </div>
        </div>
        <div class="col-md-4 feature_project_img"> <img src={`${process.env.PUBLIC_URL}/assets/img/icons/wordpress-icon.png`} alt="Pro Invoice System" /></div>
        <div class="col-md-4">
          <div class="feature_card">
            <ul>
              <li><strong>Refund Request Management:</strong> The system provides a streamlined process for recording and managing refund requests. Keep track of all refund-related data within Pro Invoice System, making the refund process smooth and easy to handle.</li>
              <li><strong>Efficient Invoices Download: </strong>Pro Invoice System allows you to download invoices directly from Amazon in a convenient zip format. You can easily view and access invoices, and send them to your accounts department or other relevant parties.</li>
              <li><strong>Logging and Audit Trail: </strong>With a built-in log feature, Pro Invoice System maintains a comprehensive history of all activities and changes made within the system. This ensures accountability, traceability, and the ability to troubleshoot any issues that may arise.</li>
              <li><strong>Customizable Templates: </strong>Pro Invoice System offers pre-defined templates for invoices and refund requests. You also have the flexibility to create your own templates according to your specific needs, ensuring that your invoices and refund requests reflect your branding and requirements.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="technologies_stack">
    <div class="container">
      <div class="section-title">
        <h2 class="welcome_title">Technology Stack</h2>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-2 ">
          <div class="info_card">
            <div class="card_icon clr1"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/ui-ux_icon-2.png`} alt="Pro Invoice System" /></div>
            <div class="card_info">
              <h3>UI/UX </h3>
            </div>
          </div>
        </div>
        <div class="col-md-2 mrgn_top">
          <div class="info_card">
            <div class="card_icon clr3"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/PHP_icon.png`} alt="Pro Invoice System" /></div>
            <div class="card_info">
              <h3>PHP</h3>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="info_card">
            <div class="card_icon clr4"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/mysql.png`} alt="Pro Invoice System" /></div>
            <div class="card_info">
              <h3>MySQL</h3>
            </div>
          </div>
        </div>
        <div class="col-md-2 mrgn_top">
          <div class="info_card">
            <div class="card_icon clr5"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/mannual_software_test.png`} alt="Pro Invoice System" /></div>
            <div class="card_info">
              <h3>Mannual Testing</h3>
            </div>
          </div>
        </div>
        <div class="col-md-2 ">
          <div class="info_card">
            <div class="card_icon clr5"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/yii2.png`} alt="Pro Invoice System" /></div>
            <div class="card_info">
              <h3>Yii2</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="features_img_block">
    <div class="container">
      <div class="row">
        <div class="col-md-6"><img class="w-100" src={`${process.env.PUBLIC_URL}/assets/img/portfolio/proinvoice_2.png`} alt="Pro Invoice System" /> </div>
        <div class="col-md-6"><img class="w-100" src={`${process.env.PUBLIC_URL}/assets/img/portfolio/proinvoice_3.png`} alt="Pro Invoice System" /> </div>
      </div>
    </div>
  </section>
</main>

                <Footer />
            </>
            );
        }
    }
    
export default Services;