import React from 'react'; 
import Header from '../Header';
import Footer from '../Footer';
import Topslider from '../Topslider';
import Serviceboxhome from '../Serviceboxhome';
import Aboutwhychooseusbox from '../Aboutwhychooseusbox';
import Counterhome from '../Counterhome';
import Testimonialhome from '../Testimonialhome';

class Services extends React.Component {
    render() {
      return (
            <>
                <Header />
                    <main class="zayron_srl scrolled-offset" id="main">
  <section class="portfolio_banner zayron">
    <div class="container">
      <div class="section-title">
        <p class="web_indicator mb-2">Portfolio / Web</p>
        <h2 class="welcome_title">Welcome to Zayron SRL</h2>
        <p> The world of Refurbished technology is vast, it is everyone’s market.
          At Zayron, we believe that every euro saved by the user to obtain the same technological benefit is a goal
          achieved. The first extended reality in Italy that makes the retailer the real protagonist of the
          business. </p>
      </div>
      <div class="project_banner"><img src={`${process.env.PUBLIC_URL}/assets/img/portfolio/zayron_1.png`} alt="zayron" /></div>
    </div>
  </section>
  <section class="about_project">
    <div class="container">
      <div class="section-title">
        <h2 class="welcome_title">We are Refurbished and Used Equipment European Wholesale Distributor. </h2>
        <p> Zayron is a technology company that develops innovative solutions for the logistics industry. We are
          passionate about using technology to make logistics more efficient, sustainable, and secure.</p>
           <p>
          Our team of experienced professionals has a deep understanding of the logistics industry. We are committed
          to providing our customers with the best possible service and support. </p>
           <p>
          We are constantly innovating and developing new solutions to meet the needs of our customers. We are
          confident that Zayron is the leading technology company in the logistics industry. </p>
        <div class="visit_web headerContactCTA"> <a href="https://www.zayron.it/" target="_blank" class="">Visit website</a> </div>
      </div>
    </div>
  </section>
  <section class="project_details">
    <div class="container">
      <div class="row">
        <div class="col-md-3">
          <div class="info_card">
            <div class="card_icon name"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/project_icon1.png`} alt="zayron" /></div>
            <div class="card_info">
              <p class="label">Project Name</p>
              <h3>Zayron</h3>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="info_card">
            <div class="card_icon ctg"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/project_icon2.png`} alt="zayron" /></div>
            <div class="card_info">
              <p class="label">Category</p>
              <h3>e-commerce</h3>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="info_card">
            <div class="card_icon location"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/project_icon3.png`} alt="zayron" /></div>
            <div class="card_info">
              <p class="label">Client Location</p>
              <h3>Europe</h3>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="info_card">
            <div class="card_icon service"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/project_icon4.png`} alt="zayron" /></div>
            <div class="card_info">
              <p class="label">Services Provided</p>
              <h3>Design, Development, QA, Deployment</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="project_features">
  <div class="container">
    <div class="section-title">
      <h2 class="welcome_title">Features</h2>
    </div>
    <div class="row align-items-center">
      <div class="col-md-4">
        <div class="feature_card">
          <ul>
            <li><strong>Vast and Updated Catalog:</strong> We offer a vast catalog of new, used, and reconditioned products from a growing portfolio of brands and manufacturers. Our products undergo strict quality control protocols and come with warranties of 1 to 3 years, ensuring you receive reliable and high-quality IT solutions.</li>
            <li><strong>Tailored Support for Dealers:</strong> Zayron works closely with technology resellers and operators, providing them with comprehensive commercial assistance, credit lines, and a range of services to optimize their daily workflow. </li>
            <li><strong>Reliable IT Partner for Businesses:</strong> Whether you are a professional, small and medium-sized enterprise (SME), public body, or part of the industrial sector, Zayron is your trusted IT partner.</li>
            <li><strong>Community Support and Social Initiatives: </strong> believes in giving back to the community. We periodically equip computer labs at non-profit organizations free of charge, bringing technology into the lives of those who cannot afford it. </li>
          </ul>
        </div>
      </div>
      <div class="col-md-4 feature_project_img"><img src={`${process.env.PUBLIC_URL}/assets/img/portfolio/zayron-web.png`} alt="zayron" /></div>
      <div class="col-md-4">
        <div class="feature_card">
          <ul>
            <li><strong>Convenient Credit Management:</strong> We simplify credit management for dealers through our online platform. You can easily request a basic credit line and expand it (subject to approval) up to your maximum credit capacity. </li>
            <li><strong>Integrated Logistics and Shipping:</strong> We have partnered with trusted logistics providers such as TNT and FedEx to offer rapid and insured shipments. Our integrated shipping solutions ensure your orders are delivered promptly and securely.</li>
            <li><strong>Easy Returns Process: </strong> facilitates hassle-free returns for our customers. With direct pickup at the dealer location and no shipping costs involved, returning products has never been easier.</li>
            <li><strong>Digitized Procurement Processes:</strong> As a value-added distributor, Zayron is dedicated to digitizing procurement processes for dealers. Our online platform allows for easy order management, including immediate orders, back-orders, custom quotations, and lot liquidation.</li>
          </ul>
        </div>
      </div>
    </div>
	  </div>
    </section>

  <section class="technologies_stack">
    <div class="container">
      <div class="section-title">
        <h2 class="welcome_title">Technology Stack</h2>
      </div>
      <div class="row">
        <div class="col-md-2 mrgn_top">
          <div class="info_card">
            <div class="card_icon clr1"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/ui-ux_icon-2.png`} alt="zayron" /></div>
            <div class="card_info">
              <h3>UI/UX </h3>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="info_card">
            <div class="card_icon clr2"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/magento.png`} alt="zayron" /></div>
            <div class="card_info">
              <h3>Magento 2</h3>
            </div>
          </div>
        </div>
        <div class="col-md-2 mrgn_top">
          <div class="info_card">
            <div class="card_icon clr3"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/PHP_icon.png`} alt="zayron" /></div>
            <div class="card_info">
              <h3>PHP</h3>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="info_card">
            <div class="card_icon clr4"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/mysql.png`} alt="zayron" /></div>
            <div class="card_info">
              <h3>MySQL</h3>
            </div>
          </div>
        </div>
        <div class="col-md-2 mrgn_top">
          <div class="info_card">
            <div class="card_icon clr5"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/mannual_software_test.png`} alt="zayron" /></div>
            <div class="card_info">
              <h3>Mannual Testing</h3>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="info_card">
            <div class="card_icon clr6"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/git.png`} alt="zayron" /></div>
            <div class="card_info">
              <h3>Git/Github</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="features_img_block">
    <div class="container">
      <div class="row">
        <div class="col-md-6"><img  class="w-100" src={`${process.env.PUBLIC_URL}/assets/img/portfolio/zayron_project_img2.png`} alt="zayron" /></div>
        <div class="col-md-6"><img class="w-100"  src={`${process.env.PUBLIC_URL}/assets/img/portfolio/zayron_project_img3.png`} alt="zayron" /></div>
      </div>
    </div>
  </section>
</main>
                <Footer />
            </>
            );
        }
    }
    
export default Services;