import React from 'react'; 
import Header from '../Header';
import Footer from '../Footer';
import Topslider from '../Topslider';
import Serviceboxhome from '../Serviceboxhome';
import Aboutwhychooseusbox from '../Aboutwhychooseusbox';
import Counterhome from '../Counterhome';
import Testimonialhome from '../Testimonialhome';

class Services extends React.Component {
    render() {
      return (
            <>
                <Header />
                   <main class="panamemory scrolled-offset" id="main">
  <section class="portfolio_banner panamemory">
    <div class="container">
      <div class="section-title">
        <p class="web_indicator mb-2">Portfolio / Web</p>
        <h2 class="welcome_title">Welcome To Panamemory Warranty System</h2>
        <p>Unlock the true potential of your devices with our high-quality RAM modules. Experience next-gen performance and elevate your computing experience. Upgrade your memory today and elevate your computing experience to new heights.</p>
      </div>
      <div class="project_banner"><img src={`${process.env.PUBLIC_URL}/assets/img/portfolio/panamemory_1.png`} alt="Panamemory Warranty System" /></div>
    </div>
  </section>
  <section class="about_project">
    <div class="container">
      <div class="section-title">
        <h2 class="welcome_title">Panamemory - The Ultimate Memory Solution </h2>
        <p>Unleash next-gen performance with high-quality, compatible RAM modules. Elevate your computing experience and conquer new standards. The memory choice for gamers and enthusiasts. </p>
        <p> Don't let system limitations hold you back. Upgrade your memory with Panamemory and unlock the true potential of your devices. Elevate your computing experience to new heights with our superior memory solutions. </p>
        <p> Gone are the days of compatibility worries. Panamemory makes it a breeze to find the perfect RAM for your Desktop PC, Laptop, or Server. </p>
        <div class="visit_web headerContactCTA"> <a href="https://panamemory.it" target="_blank" class="">Visit website</a> </div>
      </div>
    </div>
  </section>
  <section class="project_details">
    <div class="container">
      <div class="row">
        <div class="col-md-3">
          <div class="info_card">
            <div class="card_icon name"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/project_icon1.png`} alt="Panamemory Warranty System" /></div>
            <div class="card_info">
              <p class="label">Project Name</p>
              <h3> Panamemory Warranty System</h3>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="info_card">
            <div class="card_icon ctg"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/project_icon2.png`} alt="Panamemory Warranty System" /></div>
            <div class="card_info">
              <p class="label">Category</p>
              <h3>e-commerce</h3>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="info_card">
            <div class="card_icon location"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/project_icon3.png`} alt="Panamemory Warranty System" /></div>
            <div class="card_info">
              <p class="label">Client Location</p>
              <h3>Europe</h3>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="info_card">
            <div class="card_icon service"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/project_icon4.png`} alt="Panamemory Warranty System" /></div>
            <div class="card_info">
              <p class="label">Services Provided</p>
              <h3>Design, Development, QA, Deployment</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="project_features">
    <div class="container">
      <div class="section-title">
        <h2 class="welcome_title">Features</h2>
      </div>
      <div class="row align-items-center">
        <div class="col-md-4">
          <div class="feature_card">
            <ul>
              <li><strong>Extensive Compatibility: </strong>Panamemory offers a wide range of RAM modules that are compatible with various devices, including Desktop PCs, Laptops, and Servers, ensuring you can find the perfect fit for your specific system.</li>
              <li><strong>Next-Gen Performance: </strong> Experience the power of Panamemory DDR4 memory modules, which enable you to exceed system limitations and set new standards. Enjoy seamless multitasking and unleash the potential of resource-intensive applications.</li>
              <li><strong>High Density Modules:</strong>With higher density modules, Panamemory allows for greater RAM capacity, enabling you to maximize the memory potential of your device and achieve optimal performance.</li>
              <li><strong>Tailored for Gamers: </strong> Panamemory understands the needs of gamers and provides memory solutions that cater to their requirements. Enhance your gaming experience with reliable and high-performance RAM modules.</li>
            </ul>
          </div>
        </div>
        <div class="col-md-4 feature_project_img"><img src={`${process.env.PUBLIC_URL}/assets/img/portfolio/panamemory-web.png`} alt="Panamemory Warranty System" /></div>
        <div class="col-md-4">
          <div class="feature_card">
            <ul>
              <li><strong>Easy Selection Process: </strong> Panamemory's user-friendly website makes it quick and easy to find compatible RAM for your device. Simplify the selection process and confidently choose the right memory module.</li>
              <li><strong>Rigorous Testing: </strong>All Panamemory memory modules undergo rigorous testing to ensure reliability, stability, and optimal performance. You can trust the quality of our products for a seamless computing experience.</li>
              <li><strong>Exceptional Customer Support: </strong> Panamemory is dedicated to providing excellent customer support. Whether you have questions about compatibility or need assistance with your purchase, our team is ready to help.</li>
              <li><strong>Warranty Protection:</strong> Panamemory stands behind the quality of its products and offers warranty protection for added peace of mind. Enjoy the confidence of knowing that your investment is protected.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="technologies_stack">
    <div class="container">
      <div class="section-title">
        <h2 class="welcome_title">Technology Stack</h2>
      </div>
      <div class="row">
        <div class="col-md-2 mrgn_top">
          <div class="info_card">
            <div class="card_icon clr1"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/ui-ux_icon-2.png`} alt="Panamemory Warranty System" /></div>
            <div class="card_info">
              <h3>UI/UX </h3>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="info_card">
            <div class="card_icon clr2"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/html-icon.png`} alt="Panamemory Warranty System" /></div>
            <div class="card_info">
              <h3>HTML 5</h3>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="info_card">
            <div class="card_icon clr6"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/wordpress-icon.png`} alt="Panamemory Warranty System" /></div>
            <div class="card_info">
              <h3>WordPress</h3>
            </div>
          </div>
        </div>
        <div class="col-md-2 mrgn_top">
          <div class="info_card">
            <div class="card_icon clr3"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/PHP_icon.png`} alt="Panamemory Warranty System" /></div>
            <div class="card_info">
              <h3>PHP</h3>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="info_card">
            <div class="card_icon clr4"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/mysql.png`} alt="Panamemory Warranty System" /></div>
            <div class="card_info">
              <h3>MySQL</h3>
            </div>
          </div>
        </div>
        <div class="col-md-2 mrgn_top">
          <div class="info_card">
            <div class="card_icon clr5"><img src={`${process.env.PUBLIC_URL}/assets/img/icons/mannual_software_test.png`} alt="Panamemory Warranty System" /></div>
            <div class="card_info">
              <h3>Mannual Testing</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="features_img_block">
    <div class="container">
      <div class="row">
        <div class="col-md-6"><img class="w-100" src={`${process.env.PUBLIC_URL}/assets/img/portfolio/panamemory_img3-768x768.png`} alt="Panamemory Warranty System" /></div>
        <div class="col-md-6"><img class="w-100" src={`${process.env.PUBLIC_URL}/assets/img/portfolio/panamemory-img4-768x768.png`} alt="Panamemory Warranty System" /></div>
      </div>
    </div>
  </section>
</main>

                <Footer />
            </>
            );
        }
    }
    
export default Services;