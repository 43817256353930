import React from 'react'; 

function Aboutusinfobox(props)
{
    return(
            <>
                <div className="col-lg-6">
                    <img src={props.pageImg} className="img-fluid" alt="" />
                </div>
            </>
    );
}


    
export default Aboutusinfobox;