import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import Portfoliosectionbox from "../Portfoliosectionbox";
import { Link } from "react-router-dom";

class Portfolio extends React.Component {
  render() {
    return (
      <>
        <Header />
        <main id="main">
          <section class="portfolio-slider scrolled-offset">
            <div class="container">
              <div
                id="carouselExampleAutoplaying"
                class="carousel slide"
                data-bs-ride="carousel"
              >
                <div class="carousel-indicators">
                  <button
                    type="button"
                    data-bs-target="#carouselExampleCaptions"
                    data-bs-slide-to="0"
                    class="active"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleCaptions"
                    data-bs-slide-to="1"
                    aria-label="Slide 2"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleCaptions"
                    data-bs-slide-to="2"
                    aria-label="Slide 3"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleCaptions"
                    data-bs-slide-to="3"
                    aria-label="Slide 4"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleCaptions"
                    data-bs-slide-to="4"
                    aria-label="Slide 5"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleCaptions"
                    data-bs-slide-to="5"
                    aria-label="Slide 6"
                  ></button>
                </div>
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <div class="slider_card row">
                      <div class="col-md-6">
                        <div class="slider_img">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/img/portfolio/zayron_img-600x344.png`}
                            class="img-fluid"
                            alt="zayron_img"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="slider_body">
                          <h4>The Zayron Europe SRL</h4>
                          <p>
                            The Zayron Europe SRL Project was born as an
                            evolution of a business started in the distant 90s,
                            in full success of vertical distribution. Over the
                            years to come, the distributor has been transformed
                            several times to adapt to an increasingly horizontal
                            and competitive market. The CEO today at the helm of
                            the company boasts over 20 years of experience in
                            the IT sector, not only in the pure commercial part,
                            but above all in the technological and instrumental
                            one; all the personnel currently operating in the
                            Zayron circuit are trained both from a technical and
                            an application point of view, in the WebDev, Cloud
                            Computing, Security and Networking fields.
                          </p>
                          <Link to="/zyron" className="btn btn-outline-light">
                            View More
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="carousel-item">
                    <div class="slider_card row">
                      <div class="col-md-6">
                        <div class="slider_img">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/img/portfolio/orange_img-600x344.png`}
                            class="img-fluid"
                            alt="orange_img"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="slider_body">
                          <h4>Orange</h4>
                          <p>
                            Orange Worldwide IT Distributor history founds its
                            roots more than 5 years ago when our foundation team
                            took action to become distributors in the IT market,
                            connecting manufacturers and vendors to the final
                            customers. We believe that every business is unique
                            and deserves the best customers that it can get.
                            That is why our mission is to connect +50.000
                            manufacturers and vendors to the right IT niche.
                            Every product we ship is of high quality and
                            deserves to be on every continent of the world.
                          </p>
                          <Link to="/orange" className="btn btn-outline-light">
                            View More
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="carousel-item">
                    <div class="slider_card row">
                      <div class="col-md-6">
                        <div class="slider_img">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/img/portfolio/repricer_img-600x344.png`}
                            alt="repricer_img"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="slider_body">
                          <h4>Arsgate Repricer</h4>
                          <p>
                            The faster you react to market changes, the better
                            your chances of making a sale. Arsgate Repricer
                            reacts to changes your competitors make in 90
                            seconds, giving you the best possible chance of
                            making that sale across all of your
                            marketplaces.Evaluate different attributes like
                            seller rating, pricing and fulfillment to get in the
                            winning position more than your competitors.{" "}
                          </p>
                          <Link
                            to="/repricer"
                            className="btn btn-outline-light"
                          >
                            View More
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="carousel-item">
                    <div class="slider_card row">
                      <div class="col-md-6">
                        <div class="slider_img">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/img/portfolio/panamemory_img-1-600x344.png`}
                            alt="panamemory_img"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="slider_body">
                          <h4>Panamemory Warranty System</h4>
                          <p>
                            {" "}
                            Panamemory is known for many things, from memory and
                            storage solutions like solid-state drives (SSDs) and
                            encrypted USBs to high-performance memory. These
                            products can be found everywhere: around people's
                            homes, offices, even satellites and beyond. However,
                            even with our status as a global leader in the tech
                            industry, Panamemory is made of amazing people who
                            operate more like a family than a large corporation.
                            Delivering the end-to-end solutions that streamline
                            and supercharge everything you do in today�s
                            ever-growing global datasphere. Serving global data
                            which impacts our lives means providing the scale,
                            capacity and the ability to work with worldwide
                            partners.
                          </p>
                          <Link
                            to="/panamemory"
                            className="btn btn-outline-light"
                          >
                            View More
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="carousel-item">
                    <div class="slider_card row">
                      <div class="col-md-6">
                        <div class="slider_img">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/img/portfolio/newsko_img-768x440.png`}
                            alt="panamemory_img"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="slider_body">
                          <h4> Newsko Distribution </h4>
                          <p>
                            Newsko Distribution oriented to the creation of
                            value, has always based its mission on elements that
                            distinguish it: competence, quality and innovation.
                            The broad portfolio of Information & Communication
                            Technology products, presence on the national
                            territory with multiple sales offices, efficient
                            logistics and the e-commerce platform, are just some
                            of our strengths. Freepack SRL has won the trust of
                            an ever-increasing number of Computer Shops, VARs,
                            System Integrators, Chains, E-tailers and GD,
                            providing them with technical-commercial advice and
                            the appropriate tools to face a constantly evolving
                            market. The search for new models of relationship
                            with customers, with passion, creativity and
                            innovation, places Freepack SRL alongside its Dealer
                            partners, guiding them in business and in everyday
                            choices.
                          </p>
                          <Link to="/newsko" className="btn btn-outline-light">
                            View More
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="carousel-item">
                    <div class="slider_card row">
                      <div class="col-md-6">
                        <div class="slider_img">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/img/portfolio/proinvoice_img-600x344.png`}
                            class="img-fluid"
                            alt="Logo"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="slider_body">
                          <h4> PRO Invoice System </h4>
                          <p>
                            The new system of e-invoicing ensures
                            standardization of invoices, bringing a systematic
                            methodology to how B2B business is conducted.
                            E-invoicing cuts out a number of steps in the
                            invoicing process thereby saving time for the buyer
                            and the seller. With e-invoicing, the cost for paper
                            or postal fees is saved. E-invoicing encourages
                            automation, thereby reducing errors and mistakes
                            that are common with manual input.
                          </p>
                          <Link
                            to="/invoices"
                            className="btn btn-outline-light"
                          >
                            View More
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  class="carousel-control-prev navigation-btn"
                  type="button"
                  data-bs-target="#carouselExampleAutoplaying"
                  data-bs-slide="prev"
                >
                  <span
                    class="carousel-control-prev-icon navigation-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button
                  class="carousel-control-next navigation-btn"
                  type="button"
                  data-bs-target="#carouselExampleAutoplaying"
                  data-bs-slide="next"
                >
                  <span
                    class="carousel-control-next-icon navigation-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </section>
          <section class="portfolio-block">
            <div class="container">
              <div class="row">
                <div class="col-md-4">
                  <Link to="/repricer" className="portfolio_card">
                    <div class="card-img">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/img/portfolio/repricer_thumb.jpg`}
                        alt="arsgate repricer"
                      />
                    </div>
                    <div class="card-content">
                      <h4>Arsgate Repricer</h4>
                    </div>
                  </Link>
                </div>
                <div class="col-md-4">
                  <Link to="/zyron" className="portfolio_card">
                    <div class="card-img">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/img/portfolio/zayron_thumb.jpg`}
                        alt="zayron eurom SRL"
                      />
                    </div>
                    <div class="card-content">
                      <h4>The Zayron Europe SRL</h4>
                    </div>
                  </Link>
                </div>
                <div class="col-md-4">
                  <Link to="/orange" className="portfolio_card">
                    <div class="card-img">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/img/portfolio/orange_thumb.jpg`}
                        alt="orange"
                      />
                    </div>
                    <div class="card-content">
                      <h4>Orange</h4>
                    </div>
                  </Link>
                </div>
                <div class="col-md-4">
                  <Link to="/panamemory" className="portfolio_card">
                    <div class="card-img">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/img/portfolio/panamemory_thum.jpg`}
                        alt="panamemory"
                      />
                    </div>
                    <div class="card-content">
                      <h4>Panamemory Warranty System</h4>
                    </div>
                  </Link>
                </div>
                <div class="col-md-4">
                  <Link to="/newsko" className="portfolio_card">
                    <div class="card-img">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/img/portfolio/newsko_thumb.jpg`}
                        alt="newsko"
                      />
                    </div>
                    <div class="card-content">
                      <h4>Newsko Distribution</h4>
                    </div>
                  </Link>
                </div>
                <div class="col-md-4">
                  <Link to="/invoices" className="portfolio_card">
                    <div class="card-img">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/img/portfolio/pro-invoice_thumb.jpg`}
                        alt="pro-invoice"
                      />
                    </div>
                    <div class="card-content">
                      <h4>PRO Invoice System</h4>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </>
    );
  }
}

export default Portfolio;
